import React, { Fragment, useState, useEffect } from "react";
import LoadingBtn from "../../components/LoadingBtn";
import { useFormik } from "formik";
import * as Yup from "yup";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";

import endpoints from "../../api/endpoints";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Image from "../../images/user-avatar-80.png";
import { Link } from 'react-router-dom';

import {toast } from "react-toastify";

function AccountPanel(props) {
  const [avatar, setAvatar] = useState(Image);
  const [newAvatar, setNewAvatar] = useState();
  const [newAvatarBlob, setNewAvatarBlob] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const refresh = useRefreshToken();

  const { auth, setAuth } = useAuth();

  console.log(auth.user);

  // Upload Profile Picture

  const uploadProfilePictureHandler = async () => {
    if (newAvatar) {
      try {
        setIsUploading(true);

        // Upload Profile Picture
        let formData = new FormData();
        formData.append("avatar", newAvatarBlob);

        const res = await axiosPrivate.post(endpoints.UPLOAD_AVATAR, formData);

        setAvatar(res.data.UPLOAD);

        // Reset
        setNewAvatarBlob(null);
        setNewAvatar(null);

        // Set New Profile Picture
        await axiosPrivate.patch(endpoints.PROFILE_URL, {
          avatar: res.data.UPLOAD,
        });

        setAuth({
          ...auth,
          user: { ...auth.user, avatar: res.data.UPLOAD },
        });
      } catch (error) {
        console.error(error);
        // navigate('/signin', { state: { from: location }, replace: true });
      } finally {
        setIsUploading(false);
        props.updateHeader();
      }
    }
  };

  const updateUserProfile = async (values) => {
    // Send patch request
    try {
      setIsSaving(true);
      const res = await axiosPrivate.patch(endpoints.PROFILE_URL, values);
      console.log(res);
      await refresh();
    } catch (e) {
      console.error(e);
      let error = e?.response.data.errors.msg;
      toast.error(error, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    // Save Changes
    console.log("Save changes...");

    uploadProfilePictureHandler();
  }, [newAvatar]);

  const profileFormSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const profileForm = useFormik({
    initialValues: {
      firstName: props?.user?.firstName,
      lastName: props?.user?.lastName,
      email: props?.user?.email,
    },
    validationSchema: profileFormSchema,
    onSubmit: (values) => {
      updateUserProfile(values);
    },
  });

  useEffect(() => {
    // Update avatar
    if (props?.user?.avatar) {
      setAvatar(props?.user?.avatar);
    }
  }, []);

  useEffect(() => {
    console.log("newAvatar: ", newAvatar);
  }, [newAvatar]);

  return (
    <div className="grow">
      <form onSubmit={profileForm.handleSubmit}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 font-bold mb-5">My Account</h2>
          {/* Picture */}
          <section>
            <div className="flex items-center">
              <div className="group relative mr-4">
                <div className="relative flex items-center justify-center">
                  {isUploading ? (
                    <svg
                      className="z-10 h-1/2 w-1/2 absolute animate-spin fill-current shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
                    </svg>
                  ) : null}

                  <img
                    className={`w-32 h-32 object-cover transition duration-200 ease-in-out rounded-full ${
                      isUploading ? "blur-sm" : "group-hover:blur-sm"
                    }`}
                    src={newAvatar ? newAvatar : avatar}
                    width="80"
                    height="80"
                    alt="User upload"
                  />
                  <div className="h-full w-full absolute flex items-center justify-center">
                    {isUploading ? (
                      <span
                        htmlFor="avatar-upload"
                        className="rounded-none btn-sm w-full invisible group-hover:visible group-hover:bg-black group-hover:text-white transition ease-in-out duration-200"
                      >
                        <svg
                          className="animate-spin w-3 h-3 fill-current shrink-0"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
                        </svg>
                        <span className="ml-2">Saving</span>
                      </span>
                    ) : (
                      <label
                        htmlFor="avatar-upload"
                        className="cursor-pointer rounded-none btn-sm w-full shadow-none text-transparent group-hover:visible group-hover:bg-black group-hover:text-white transition-all ease-in-out duration-200"
                      >
                        Change
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="space-x-2">
                <input
                  id="avatar-upload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                      if (fileReader.readyState === 2) {
                        setNewAvatar(fileReader.result);
                        setNewAvatarBlob(e.target.files[0]);
                      }
                    };
                    fileReader.readAsDataURL(e.target.files[0]);
                  }}
                />
              </div>
            </div>
          </section>
          {/* Business Profile */}
          <section>
            <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
              User Profile
            </h2>

            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
              <div className="sm:w-1/3">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="firstName"
                >
                  First Name
                </label>
                <input
                  id="firstName"
                  className="form-input w-full text-lg sm:text-base"
                  type="text"
                  onChange={profileForm.handleChange}
                  value={profileForm.values.firstName}
                />
              </div>
              <div className="sm:w-1/3">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="lastName"
                >
                  Last Name
                </label>
                <input
                  id="lastName"
                  className="form-input w-full text-lg sm:text-base"
                  type="text"
                  onChange={profileForm.handleChange}
                  value={profileForm.values.lastName}
                />
              </div>
              <div className="sm:w-1/3">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  id="email"
                  className="form-input w-full text-lg sm:text-base"
                  type="text"
                  onChange={profileForm.handleChange}
                  value={profileForm.values.email}
                />
              </div>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200">
            <div className="flex self-end">
              <Link to="/delete">
                <button
                  className="btn bg-red-500 hover:bg-red-600 text-white"
                >
                  Delete Account
                </button>
              </Link>
              <span className="ml-3">
                {isSaving ? (
                  <LoadingBtn text="Saving" />
                ) : (
                  <button
                    type="submit"
                    className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                  >
                    Save Changes
                  </button>
                )}
              </span>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}

export default AccountPanel;
